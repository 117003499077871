import * as React from 'react'
import { ResortDetails, ResortMapSector } from '@models/booking'
import { AvailableApartment } from '@models/reservation-create'

const BENEFIT_CIRCLE_MARK = 'benefit_mark_'
const BENEFIT_CIRCLE_ANIMATION = 'benefit_circle_animation_'

const AVAILABLE_LOCAL_COLOR = '#2c8f41'
const OCCUPIED_LOCAL_COLOR = '#d25e5e'
const SELECTED_LOCAL_COLOR = '#000000'

const isApartment = (element: SVGGeometryElement) => element.id.startsWith('A')
const isHouse = (element: SVGGeometryElement) => element.id.startsWith('D')

const getApartmentIdWithoutSlashSeparation = (apartment: AvailableApartment) => apartment.apartment_id.replace('/', '')

export const isForClientWithBenefit = (apartment: AvailableApartment): boolean =>
  apartment.tags.some(tag => tag.keyword === 'for_client_with_benefit')

interface SectorVisibilityParams {
  show: boolean
  sector?: string
}

interface UseClickableResortMapResponse {
  onSectorSelection: (sector: ResortMapSector) => void
}

interface Props {
  onLoadingVisibilityChange: (isLoading: boolean) => void
  onMapChange: (mapUrl: string) => void
  mapDocument: Document | null
  resortDetails: ResortDetails
  apartments: AvailableApartment[]
  selectedApartmentId: number | null
  onApartmentSelect: (apartmentId: number) => void
  onMapApartmentsChange: (apartments: AvailableApartment[]) => void
}

export const useClickableResortMap = ({
  onLoadingVisibilityChange,
  onMapChange,
  mapDocument,
  resortDetails,
  apartments,
  selectedApartmentId,
  onApartmentSelect,
  onMapApartmentsChange,
}: Props): UseClickableResortMapResponse => {
  const getMapElement = (apartment: AvailableApartment): SVGRectElement | null =>
    mapDocument?.getElementById(getApartmentIdWithoutSlashSeparation(apartment)) as SVGRectElement | null

  const getApartmentById = (elementId: string) =>
    apartments.find(apartment => getApartmentIdWithoutSlashSeparation(apartment) === elementId)

  const isApartmentSelected = (apartment: AvailableApartment) => apartment.id === selectedApartmentId

  const getLocalColor = (apartment: AvailableApartment) => {
    if (isApartmentSelected(apartment)) return SELECTED_LOCAL_COLOR
    return apartment.is_available ? AVAILABLE_LOCAL_COLOR : OCCUPIED_LOCAL_COLOR
  }

  const sectorMouseEvents = (element: SVGPolygonElement, kind: 'addEventListener' | 'removeEventListener') => {
    element[kind]('mouseenter', handleSectorShapeMouseEnter)
    element[kind]('mouseleave', handleSectorShapeMouseLeave)
    element[kind]('click', handleSectorClick)
  }

  const handleSectorShapeMouseEnter = (event: MouseEvent) => {
    const element = event.target as SVGPolygonElement
    element.style.cursor = 'pointer'
    element.style.opacity = '0.2'
    element.style.filter = 'brightness(20)'
  }

  const handleSectorShapeMouseLeave = (event: MouseEvent) => {
    const element = event.target as SVGPolygonElement
    element.style.cursor = 'default'
    element.style.filter = 'none'
    element.style.opacity = '0'
  }

  const handleSectorClick = React.useCallback(
    (event: MouseEvent) => {
      const selectedSector = event.target as SVGPolygonElement
      const sectorMap = resortDetails.map_svg_sectors.find(el => el.name === selectedSector.id)
      if (!sectorMap) return

      setSectorMap(sectorMap)
    },
    [resortDetails],
  )

  const setSectorMap = (sectorMap: ResortMapSector) => {
    onLoadingVisibilityChange(true)
    onMapChange(sectorMap.map)
  }

  const handleLocalShapeMouseEnter = (event: MouseEvent) => {
    const element = event.target as SVGGeometryElement
    element.style.cursor = 'pointer'
    element.style.fillOpacity = '0.9'
  }

  const handleLocalShapeMouseLeave = (event: MouseEvent) => {
    const element = event.target as SVGGeometryElement
    element.style.cursor = 'default'
    element.style.fillOpacity = '1'
  }

  const disableSector = (element: SVGPolygonElement) => {
    element.style.opacity = '0.5'
    element.style.cursor = 'not-allowed'
  }

  const handleLocalClick = (event: MouseEvent) => {
    const element = event.target as SVGGeometryElement
    const apartment = getApartmentById(element.id)
    if (!apartment) return
    onApartmentSelect(apartment.id)
  }

  const localMouseEvents = (element: SVGRectElement, kind: 'addEventListener' | 'removeEventListener') => {
    element[kind]('mouseenter', handleLocalShapeMouseEnter)
    element[kind]('mouseleave', handleLocalShapeMouseLeave)
    element[kind]('click', handleLocalClick)
  }

  const localShapeEvents = (action: 'register' | 'unregister') => {
    apartments.forEach((apartment: AvailableApartment) => {
      const localElement = getMapElement(apartment)
      if (localElement && apartment.is_available) {
        localMouseEvents(localElement, action === 'register' ? 'addEventListener' : 'removeEventListener')
      }
    })
  }

  const sectorShapeEvents = (action: 'register' | 'unregister') => {
    if (!resortDetails.map_svg_sectors.length) return

    const sectorShapes = mapDocument?.getElementsByTagName('polygon')
    Array.from(sectorShapes || []).forEach(shape => {
      const sector = resortDetails.map_svg_sectors.find((sector: ResortMapSector) => sector.name === shape.id)
      if (!sector) return

      const sectorApartments = apartments.some(apartment => apartment.sector === sector.id && apartment.is_available)

      if (!sectorApartments) {
        disableSector(shape)
        setSectorOccupancyMessage({ show: true, sector: sector.name })
        return
      }

      sectorMouseEvents(shape, action === 'register' ? 'addEventListener' : 'removeEventListener')
    })
  }

  React.useEffect(() => {
    clearLocalShapeSelection()
    setSelectedLocalShape()
  }, [selectedApartmentId])

  React.useEffect(() => {
    onLoadingVisibilityChange(true)

    hideLocalShapes()
    setSectorOccupancyMessage({ show: false })
    setLocalNumberVisibility()
    provideMap()
    provideBenefitMarks()

    localShapeEvents('register')
    sectorShapeEvents('register')

    setAvailableMapApartments()
    onLoadingVisibilityChange(false)

    return () => {
      localShapeEvents('unregister')
      sectorShapeEvents('unregister')
    }
  }, [mapDocument, apartments])

  const provideMap = () => {
    apartments.forEach((apartment: AvailableApartment) => {
      const element = getMapElement(apartment)
      if (!element || (!isApartment(element) && !isHouse(element))) return
      setColors(element, apartment)
    })
  }

  const provideBenefitMarks = () => {
    const benefitMarks = getBenefitMarkShapes()
    benefitMarks.forEach(clearBenefitMark)

    apartments.forEach((apartment: AvailableApartment) => {
      if (!isForClientWithBenefit(apartment)) return
      const benefitMark = benefitMarks.find(mark => mark.id === `${BENEFIT_CIRCLE_MARK}${apartment.apartment_id}`)
      if (benefitMark) showBenefitMarks(benefitMark)
    })
  }

  const setLocalNumberVisibility = () => {
    const localNumbersContainer = mapDocument?.getElementById('Locals')
    Array.from(localNumbersContainer?.children || []).forEach(localNumberShape => {
      const apartment = getApartmentById(localNumberShape.id.replace('local_', ''))
      localNumberShape.setAttribute('pointer-events', 'none')
      localNumberShape.setAttribute('opacity', apartment ? '1' : '0')
    })
  }

  const getLocalShapes = () => {
    const filterByType = (el: SVGGeometryElement) => isHouse(el) || isApartment(el)
    const rects = Array.from(mapDocument?.getElementsByTagName('rect') || []).filter(filterByType)
    const polygons = Array.from(mapDocument?.getElementsByTagName('polygon') || []).filter(filterByType)
    const paths = Array.from(mapDocument?.getElementsByTagName('path') || []).filter(filterByType)
    return [...rects, ...polygons, ...paths]
  }

  const clearBenefitMark = (benefitMark: SVGGeometryElement) => {
    const animate = mapDocument?.getElementById(`${BENEFIT_CIRCLE_ANIMATION}_${benefitMark.id}`)
    if (animate) animate.remove()
    benefitMark.style.opacity = '0'
  }

  const showBenefitMarks = (benefitMark: SVGGeometryElement) => {
    const animate = document.createElementNS('http://www.w3.org/2000/svg', 'animate')
    animate.setAttribute('id', `${BENEFIT_CIRCLE_ANIMATION}_${benefitMark.id}`)
    animate.setAttribute('attributeName', 'opacity')
    animate.setAttribute('values', '1;0.3;1')
    animate.setAttribute('dur', '1500ms')
    animate.setAttribute('repeatCount', 'indefinite')
    benefitMark.appendChild(animate)

    benefitMark.style.opacity = '1'
  }

  const getBenefitMarkShapes = (): SVGGeometryElement[] => {
    const elements = mapDocument?.getElementsByTagName('circle')
    return Array.from(elements || []).filter(element => element.id.startsWith(BENEFIT_CIRCLE_MARK))
  }

  const hideLocalShapes = () => {
    const elements = getLocalShapes()

    elements.forEach(element => {
      element.style.opacity = '0'
    })
  }

  const setAvailableMapApartments = () => {
    const elements = getLocalShapes()

    const mapApartments = elements.reduce((acc, curr) => {
      const apartment = apartments.find(apartment => getApartmentIdWithoutSlashSeparation(apartment) === curr.id)
      return apartment ? [...acc, apartment] : acc
    }, [])

    onMapApartmentsChange(mapApartments)
  }

  const setSectorOccupancyMessage = ({ show, sector = undefined }: SectorVisibilityParams) => {
    const messageShapeId = (sectorName: string) => `${sectorName}_full`

    const occupiedSectorMessages = sector
      ? [messageShapeId(sector)]
      : resortDetails.map_svg_sectors.map(sector => messageShapeId(sector.name))

    occupiedSectorMessages.forEach(occupiedSectorMessage => {
      const messageElement = mapDocument?.getElementById(occupiedSectorMessage)
      if (!messageElement) return

      messageElement.style.opacity = show ? '1' : '0'
    })
  }

  const setSelectedLocalShape = () => {
    apartments.forEach((apartment: AvailableApartment) => {
      const element = getMapElement(apartment)
      if (!element) return
      if (isApartmentSelected(apartment)) createActiveLocalShape(element)
      element.style.fill = getLocalColor(apartment)
    })
  }

  const clearLocalShapeSelection = () => {
    const rects = Array.from(mapDocument?.getElementsByTagName('rect') || [])
    const circles = Array.from(mapDocument?.getElementsByTagName('circle') || [])
    const texts = Array.from(mapDocument?.getElementsByTagName('text') || [])
    const shapes = [...rects, ...circles, ...texts]

    shapes.forEach(shape => {
      if (shape.id.startsWith('active-local')) shape.remove()
    })
  }

  const setColors = (element: SVGRectElement, apartment: AvailableApartment) => {
    element.style.opacity = '1'
    element.style.fill = getLocalColor(apartment)
  }

  return {
    onSectorSelection: setSectorMap,
  }
}

const createActiveLocalShape = (element: SVGRectElement) => {
  if (isApartment(element) || !element.height || !element.width || !element.x || !element.y) return

  const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
  circle.setAttribute('id', 'active-local')
  circle.setAttribute('stroke', 'black')
  circle.setAttribute('stroke-width', '1')
  circle.setAttribute('fill', 'white')
  circle.setAttribute('r', '8px')
  circle.setAttribute('cx', element.x.baseVal.value.toString())
  circle.setAttribute('cy', Math.ceil(element.y.baseVal.value + element.height.baseVal.value / 2).toString())

  const checkSign = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  checkSign.setAttribute('id', 'active-local')
  checkSign.setAttribute('x', (element.x.baseVal.value - 5).toString())
  checkSign.setAttribute('y', (element.y.baseVal.value + 1 + element.height.baseVal.value / 2).toString())
  checkSign.setAttribute('dominant-baseline', 'middle')
  checkSign.setAttribute('font-size', '10px')
  checkSign.setAttribute('text-anchor', 'inherit')
  checkSign.setAttribute('fill', AVAILABLE_LOCAL_COLOR)
  checkSign.innerHTML = '&#10004'

  element.parentElement?.append(circle, checkSign)
}
